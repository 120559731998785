/*global $, jQuery, alert, console*/

    "use strict";
    var isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows() );
        }
    };

    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


    $(document).ready(function () {
        controlTopMenu();
        // copyOnClipboard();

        $('.sidenav').sidenav();
        $('.modal').modal();
        // $('.tooltipped').tooltip();
        $('.collapsible').collapsible();
        // $('.dropdown-link_material').dropdown();
        // $('.tabs').tabs();
        $('.lazy').Lazy();
        $('.dropdown-trigger').dropdown();

        // function copyOnClipboard() {
        //     $('.copy-block').on('click', function() {
        //         var copyText = $(this).prev('a').text();
        //         navigator.clipboard.writeText(copyText);
        //         var element = $(this);
        //         element.addClass('active');
        //         element.addClass('active-copy');
        //         setTimeout(function(){ element.removeClass('active'); }, 200);
        //         setTimeout(function(){ element.removeClass('active-copy'); }, 1000);
        //     })
        // }


        tabConttroller();
        detectSeenElements();

        // $('.play-btn').on('click', function () {
        //     // $(this).closest('.s-video__video-wrap').addClass('active').find('.s-video__video').get(0).play();
        //
        //     $(this).closest('.s-video__video-wrap').addClass('active').find(".s-video__video-youtube")[0].src += "&autoplay=1";
        // });


        $('.animated-scroll').on('click', function (e) {
            e.preventDefault();
            var linkHref = $(this).attr('href');
            $('html, body').animate({
                scrollTop: $(linkHref).offset().top - 120
            }, 500);
        });

        function controlTopMenu() {
            var scrollHeight = $(window).scrollTop();

            if(scrollHeight  > 0) {
                $('.top-menu').addClass('not-top');
            } else {
                $('.top-menu').removeClass('not-top');
            }
            $(window).scroll(function() {
                scrollHeight = $(window).scrollTop();

                if(scrollHeight  > 0) {
                    $('.top-menu').addClass('not-top');
                } else {
                    $('.top-menu').removeClass('not-top');
                }
            });


        }

        function detectSeenElements() {
            var sections = $('section, .s-services__block, .s-services-mini__block');

            sections.each(function () {
                var top_of_element = $(this).offset().top + $(window).innerHeight()/2;
                var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                var top_of_screen = $(window).scrollTop();

                if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                    $(this).addClass('seen')
                } else {
                    // the element is not visible, do something else
                }
            })

            $(window).scroll(function() {

                sections.each(function () {
                    var top_of_element = $(this).offset().top + $(window).innerHeight()/2;
                    var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                    var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                    var top_of_screen = $(window).scrollTop();

                    if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                        $(this).addClass('seen')
                    } else {
                        // the element is not visible, do something else
                    }
                })
            });
        }

        function tabConttroller() {
            $('.tab-btn').on('click', function (e) {
                e.preventDefault();
                let curIndex = $(this).attr('data-index');

                $(this).closest('.s-tab__btns-wrap').find('.tab-btn').each(function () {
                    $(this).removeClass('active')
                    if ($(this).attr('data-index') == curIndex) {
                        $(this).addClass('active')
                    }
                })

                $(this).closest('.s-tab__content').find('.s-tab__img').each(function () {
                    $(this).removeClass('active')
                    if ($(this).attr('data-index') == curIndex) {
                        $(this).addClass('active')
                    }
                })

                $(this).closest('.s-tab__content').find('.s-tab__tab-content').each(function () {
                    $(this).removeClass('active')
                    if ($(this).attr('data-index') == curIndex) {
                        $(this).addClass('active')
                    }
                })


            })
        }


        $('.s-slider__slider').slick({
            arrows: true,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            speed: 1000,
            prevArrow: '<div class="prev-btn"></div>',
            nextArrow: '<div class="next-btn"></div>'
        });
        $('.s-slider__slider .slick-slide').on('click', function () {
            var slideIndex = $(this).attr('data-slick-index')
            $('.s-slider__slider').slick('slickGoTo', slideIndex );
        });

        // $('body').on('click','.gdpr-cookie-notice-modal-footer-item-save', function () {
        //     $('.gdpr-cookie-notice-modal').css('display', 'none');
        //     $('.gdpr-cookie-notice').css('display', 'none');
        // });
        //
        // $('body').on('click','.gdpr-cookie-notice-modal-footer-item-statement', function () {
        //     $('#privacy_popup').modal('open');
        // });

        copyOnClipboard();
        function copyOnClipboard() {
            // if (isSafari) {
            //     $('.copy-block').each(function () {
            //         $(this).hide();
            //     })
            // }
            $('.copy-block').on('click', function() {
                var copyText = $(this).prev('a').text();
                var $temp = $("<input>");
                $("body").append($temp);
                $temp.val(copyText).select();
                document.execCommand("copy");
                $temp.remove();
                var element = $(this);
                element.addClass('active');
                element.addClass('active-copy');
                setTimeout(function(){ element.removeClass('active'); }, 200);
                setTimeout(function(){ element.removeClass('active-copy'); }, 1000);
            })
        }

        // function copyToClipboard(elem) {
        //     // create hidden text element, if it doesn't already exist
        //     var targetId = "_hiddenCopyText_";
        //     var isInput = elem.tagName === "INPUT" || elem.tagName === "TEXTAREA";
        //     var origSelectionStart, origSelectionEnd;
        //     if (isInput) {
        //         // can just use the original source element for the selection and copy
        //         target = elem;
        //         origSelectionStart = elem.selectionStart;
        //         origSelectionEnd = elem.selectionEnd;
        //     } else {
        //         // must use a temporary form element for the selection and copy
        //         target = document.getElementById(targetId);
        //         if (!target) {
        //             var target = document.createElement("textarea");
        //             target.style.position = "absolute";
        //             target.style.left = "-9999px";
        //             target.style.top = "0";
        //             target.id = targetId;
        //             document.body.appendChild(target);
        //         }
        //         target.textContent = elem.textContent;
        //     }
        //     // select the content
        //     var currentFocus = document.activeElement;
        //     target.focus();
        //     target.setSelectionRange(0, target.value.length);
        //
        //     // copy the selection
        //     var succeed;
        //     try {
        //         succeed = document.execCommand("copy");
        //     } catch(e) {
        //         succeed = false;
        //     }
        //     // restore original focus
        //     if (currentFocus && typeof currentFocus.focus === "function") {
        //         currentFocus.focus();
        //     }
        //
        //     if (isInput) {
        //         // restore prior selection
        //         elem.setSelectionRange(origSelectionStart, origSelectionEnd);
        //     } else {
        //         // clear temporary content
        //         target.textContent = "";
        //     }
        //     return succeed;
        // }
        // $('.copy-block').on('click', function() {
        //     var copyText = $(this).prev('a').text();
        //     copyToClipboard(copyText);
        // })



        $(window).resize(function() {
            addInlineSvg();
        });



        function addInlineSvg() {
            var mySVGsToInject = document.querySelectorAll('img.image_svg');
            SVGInjector(mySVGsToInject);
        }
        addInlineSvg();


    });

    window.addEventListener("load", function() {
        if ($('#headerVideo').length) {
            $('#headerVideo').get(0).play();
        }
    });

